import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
// material-ui
import {
  Button,
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  CardBody,
  Card,
  CardHeader,
  CardTitle,
  ModalFooter,
} from 'reactstrap';

// third-party
import { Form, FormikProvider, useFormik } from 'formik';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// project-imports
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import { updatePromotionProductEligibility } from '../../../actions/promotion/index';
import { getRewardsMaster } from '../../../actions/rewards';
import { QueryBuilderApp } from '../QueryBuilderApp';
import { v4 as uuidv4 } from 'uuid';
import Tier from './Tier';
import BasketPlayground from '../BasketPlayground';
import { EFFECT_TYPES } from '../../../constants';
import { convertFieldToQueryBuilderFormat } from '../../../helpers/convertFieldToQueryBuilderFormat';
import { getAllFields } from '../../../actions/promotion/fields';
import { useDispatch, useSelector } from 'react-redux';
import InnerLoader from '../../../components/Common/InnerLoader';
import { Else, If, Then } from 'react-if';
import ErrorMessagesInLang from '../../Configuration/Fields/ErrorMessagesInLang';

// ==============================|| PROMOTION TIERS ||============================== //

const discountTypes = [
  {
    label: 'Percentage',
    value: 'percentage',
    type: ['rewardPoints', 'transactions'],
  },
  {
    label: 'Fixed',
    value: 'fixed',
    type: ['rewardPoints', 'transactions'],
  },

  {
    label: 'Group',
    value: 'segmented',
    type: ['rewardPoints', 'transactions'],
  },
  {
    label: 'Group - Unit Based',
    value: 'unitBased',
    type: ['transactions'],
  },
  {
    label: 'Dynamic Groups',
    value: 'dynamicGroup',
    type: ['rewardPoints', 'transactions'],
  },
  {
    label: 'Combo',
    value: 'combo',
    type: ['transactions'],
  },
  {
    label: 'Gift',
    value: 'gift',
    type: ['transactions'],
  },
  {
    label: 'Formula',
    value: 'formula',
    type: ['transactions', 'rewardPoints'],
  },
];

const rewardTypes = [
  {
    label: 'Fixed',
    value: 'fixed',
  },
  {
    label: 'Formula',
    value: 'rewardFormula',
  },
];

const walletTypes = [
  {
    label: 'Formula',
    value: 'walletFormula',
  },
  {
    label: 'Fixed',
    value: 'fixed',
  },
];

const PromotionTiers = ({
  onCancel,
  getStores = () => {},
  promotionId,
  promotionDetail,
  updateHandler = () => {},
  partners = [],
}) => {
  const navigate = useNavigate();

  const [tiers, setTiers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showProductEligibility, setShowProductEligibility] = useState(false);
  const [rewardList, setRewardList] = useState([]);
  const [enableMilestone, setEnableMilestone] = useState(false);

  const getRewardsList = async () => {
    try {
      const data = {
        pageOffset: 0,
        pageSize: 100,
      };
      const res = await getRewardsMaster(data);
      setRewardList(
        res?.data?.rewardTypes?.map((rw) => {
          return {
            label: rw?.name || '',
            value: rw?.rewardTypeId || '',
          };
        }) || []
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRewardsList();
  }, []);

  const [productEligibilityQuery, setProductEligibilityQuery] = useState({
    combinator: 'and',
    rules: [],
  });
  const [itemEligibilityErrors, setItemEligibilityErrors] = useState([]);
  const [rulesAndEffectsErrors, setRulesAndEffectsErrors] = useState([]);
  const dispatch = useDispatch();

  const getFields = async () => {
    try {
      const { data } = await getAllFields(
        {
          pageOffset: Math.max(0),
          pageSize: 2000,
        },
        dispatch
      );

      // setFields(convertFieldToQueryBuilderFormat(data));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (promotionDetail) {
      setProductEligibilityQuery({
        ...(promotionDetail?.productEligibility?.rule || {
          combinator: 'and',
          rules: [],
        }),
      });
      setItemEligibilityErrors([
        ...(promotionDetail?.productEligibility?.itemEligibilityErrors || []),
      ]);
      setEnableMilestone(
        !!promotionDetail?.productEligibility?.enableMilestone
      );
      setTiers(promotionDetail?.productEligibility?.rulesAndEffects);
      setRulesAndEffectsErrors([
        ...(promotionDetail?.productEligibility?.noRulesAppliedErrors || []),
      ]);
      getFields();
    }
  }, [promotionDetail]);

  const updatePromotionProductEligibilityFunc = async () => {
    try {
      setIsLoading(true);

      const variables = {
        input: {
          promotionId: promotionId,
          productEligibility: {
            rule: productEligibilityQuery,
            rulesAndEffects: tiers,
            itemEligibilityErrors,
            rulesAndEffectsErrors,
            enableMilestone,
          },
        },
      };

      const { data, success } = await updatePromotionProductEligibility(
        variables
      );

      if (success) {
        updateHandler();
        alertSuccess('Promotion product eligibility updated successfully.');
      } else {
        alertError('Failed!, Please try after some time.');
      }

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  const tierChangeHandler = (fieldName, value, tIndex) => {
    try {
      const newTier = {
        ...tiers?.[tIndex],
        [fieldName]: value,
      };
      const newTiers = [...tiers];
      newTiers[tIndex] = newTier;
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const effectChangeHandler = (fieldName, value, eIndex, tIndex) => {
    try {
      let newEffect = {
        ...tiers?.[tIndex]?.effects?.[eIndex],
        [`${fieldName}`]: value,
      };

      if (fieldName === 'effectType') {
        newEffect = {
          [`${fieldName}`]: value,
          discountType:
            value === 'transactions' ? discountTypes?.[0]?.value : null,
          walletType: value === 'wallet' ? walletTypes?.[0]?.value : null,
          rewardType: value === 'rewardPoints' ? rewardTypes?.[0]?.value : null,
        };
      }

      if (fieldName === 'walletType' && value === 'walletFormula') {
        newEffect = {
          ...newEffect,
          walletAmount: null,
          maxLimit: null,
        };
      }

      if (fieldName === 'walletType' && value === 'fixed') {
        newEffect = {
          ...newEffect,
          walletFormula: null,
        };
      }

      if (fieldName === 'rewardType' && value === 'rewardFormula') {
        newEffect = {
          ...newEffect,
          rewardValue: null,
          maxLimit: null,
        };
      }

      if (fieldName === 'rewardType' && value === 'fixed') {
        newEffect = {
          ...newEffect,
          rewardFormula: null,
        };
      }

      const newEffects = [...tiers?.[tIndex]?.effects];
      newEffects[eIndex] = newEffect;
      const newTier = {
        ...tiers?.[tIndex],
        effects: newEffects,
      };
      const newTiers = [...tiers];
      newTiers[tIndex] = newTier;
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteEffectHandler = (eIndex, tIndex) => {
    try {
      const newEffects = [
        ...tiers[tIndex]?.effects?.filter((item, index) => eIndex !== index),
      ];
      const newTier = {
        ...tiers[tIndex],
        effects: newEffects,
      };
      const newTiers = [...tiers];
      newTiers[tIndex] = newTier;
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteRuleHandler = (tIndex) => {
    try {
      const newTiers = [...tiers?.filter((item, index) => index !== tIndex)];
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const onDragEnd = async (result) => {
    try {
      if (!result?.destination) {
        return;
      }

      if (result?.type === 'tier') {
        const updatedTiers = [...tiers];
        const [removed] = updatedTiers?.splice(result?.source?.index, 1);
        updatedTiers?.splice(result?.destination?.index, 0, removed);
        setTiers([...updatedTiers]);
      }

      if (result?.type === 'effect') {
        const tIndex = Number(result?.destination?.droppableId);

        const newEffects = [...tiers?.[tIndex]?.effects];
        const [removed] = newEffects?.splice(result?.source?.index, 1);
        newEffects?.splice(result?.destination?.index, 0, removed);

        const newTier = {
          ...tiers?.[tIndex],
          effects: newEffects,
        };

        const newTiers = [...tiers];
        newTiers[tIndex] = newTier;
        setTiers([...newTiers]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateTierQuery = (value, tIndex) => {
    try {
      const newTier = {
        ...tiers?.[tIndex],
        rule: value,
      };
      const newTiers = [...tiers];
      newTiers[tIndex] = { ...newTier };
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const deepCloneWithNewIds = (obj) => {
    try {
      if (Array?.isArray(obj)) {
        return obj?.map((item) => deepCloneWithNewIds(item));
      } else if (typeof obj === 'object' && obj !== null) {
        return Object?.keys(obj).reduce((acc, key) => {
          acc[key] =
            key === 'id' ? generateId() : deepCloneWithNewIds(obj[key]);
          return acc;
        }, {});
      }
      return obj;
    } catch (e) {
      console.error(e);
    }
  };

  const duplicateEffect = (eIndex, tIndex) => {
    try {
      const newEffects = [...tiers?.[tIndex]?.effects];
      const clonedEffect = deepCloneWithNewIds(newEffects?.[eIndex]);
      newEffects?.splice(eIndex + 1, 0, clonedEffect);
      const newTier = {
        ...tiers[tIndex],
        effects: newEffects,
      };
      const newTiers = [...tiers];
      newTiers[tIndex] = newTier;
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const duplicateRule = (tIndex) => {
    try {
      const newTiers = [...tiers];
      const clonedRule = deepCloneWithNewIds(newTiers?.[tIndex]);
      newTiers?.splice(tIndex + 1, 0, clonedRule);
      setTiers([...newTiers]);
    } catch (e) {
      console.log(e);
    }
  };

  const generateId = () => {
    return uuidv4();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (promotionId) {
          updatePromotionProductEligibilityFunc();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  return (
    <CardBody className="p-4">
      <Row columnSpacing={2}>
        <Col lg={7} md={12} className="position-relative">
          <DragDropContext onDragEnd={onDragEnd}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" onSubmit={handleSubmit}>
                <Card className="shadow-none">
                  <CardHeader className="p-0 border-0">
                    <CardTitle className="fs-14 fw-medium mb-0 ">
                      Item Eligibility
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-0">
                    <If
                      condition={
                        productEligibilityQuery?.rules?.length ||
                        showProductEligibility
                      }
                    >
                      <Then>
                        <Row>
                          <QueryBuilderApp
                            data={productEligibilityQuery}
                            updateQuery={setProductEligibilityQuery}
                            fieldGroups={['item']}
                          />

                          <Col xs={12} className="mt-2">
                            <FormGroup>
                              <ErrorMessagesInLang
                                value={
                                  promotionDetail?.productEligibility
                                    ?.itemEligibilityErrors || []
                                }
                                onChange={(values) => {
                                  setItemEligibilityErrors(
                                    values.filter((val) => val.value)
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Then>
                      <Else>
                        <Row>
                          <Col>
                            <div className="px-3 py-4 bg-light border rounded-1 border-soft-dark text-center">
                              <h4>
                                All Products are eligible for this campaign
                              </h4>
                              <p>
                                Do you want to choose select products based on
                                the filter
                              </p>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light d-flex gap-1 m-auto btn-sm"
                                onClick={() => {
                                  setShowProductEligibility(true);
                                }}
                              >
                                <i className="bx bx-plus fs-18"></i> Configure
                                Product Eligibility
                              </button>
                            </div>
                          </Col>
                        </Row>
                      </Else>
                    </If>
                    <Row>
                      <Col xs={12}>
                        <FormGroup switch>
                          <Input
                            type="switch"
                            id="enableMilestone"
                            checked={enableMilestone}
                            onChange={(e) =>
                              setEnableMilestone(e.target.checked)
                            }
                          />
                          <Label for="enableMilestone" check>
                            Enable Milestone
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>

                <Card className="shadow-none rulesEffects">
                  <CardHeader className="p-0 border-0 d-flex ">
                    <CardTitle className="fs-14 fw-medium mb-0 ">
                      Rules and Effects
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="px-0">
                    <Row>
                      <Col>
                        <Droppable droppableId="droppable" type="tier">
                          {(provided, snapshot) => (
                            <div
                              {...provided?.droppableProps}
                              ref={provided?.innerRef}
                            >
                              {tiers?.map((tier, tIndex) => {
                                return (
                                  <Draggable
                                    key={tier?.id?.toString()}
                                    draggableId={tier?.id?.toString()}
                                    index={tIndex}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided?.innerRef}
                                        {...provided?.draggableProps}
                                      >
                                        <Tier
                                          tier={tier}
                                          tIndex={tIndex}
                                          duplicateRule={duplicateRule}
                                          deleteRuleHandler={deleteRuleHandler}
                                          updateTierQuery={updateTierQuery}
                                          generateId={generateId}
                                          effectTypes={EFFECT_TYPES}
                                          discountTypes={discountTypes}
                                          tiers={tiers}
                                          setTiers={setTiers}
                                          duplicateEffect={duplicateEffect}
                                          tierChangeHandler={tierChangeHandler}
                                          effectChangeHandler={
                                            effectChangeHandler
                                          }
                                          deleteEffectHandler={
                                            deleteEffectHandler
                                          }
                                          rewardTypes={rewardTypes}
                                          walletTypes={walletTypes}
                                          rewardList={rewardList || []}
                                          promotionId={promotionId}
                                          draghandleProps={
                                            provided?.dragHandleProps
                                          }
                                          enableMilestone={enableMilestone}
                                          partners={partners}
                                        />
                                      </div>
                                    )}
                                  </Draggable>
                                );
                              })}
                            </div>
                          )}
                        </Droppable>
                      </Col>
                    </Row>

                    <If condition={tiers?.length}>
                      <Then>
                        <Row>
                          <Col xs={12} className="mt-2">
                            <FormGroup>
                              <ErrorMessagesInLang
                                addTitle="Set error messages non of rule conditions are met"
                                editTitle="Set error messages non of rule conditions are met ✍️"
                                value={
                                  promotionDetail?.productEligibility
                                    ?.rulesAndEffectsErrors || []
                                }
                                onChange={(values) => {
                                  setRulesAndEffectsErrors(
                                    values.filter((val) => val.value)
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </Then>
                    </If>

                    <Row>
                      <Col>
                        <div className="px-3 py-5 bg-light border rounded-1 border-soft-dark text-center">
                          <h4>
                            Add rules and configure conditions and Effects
                          </h4>
                          <p>
                            You can add more than one rule with different
                            conditions and effects by clicking on the Create
                            Rule button
                          </p>
                          <button
                            type="button"
                            className="btn btn-primary waves-effect waves-light d-flex gap-1 m-auto"
                            onClick={() => {
                              setTiers([
                                ...(tiers || []),
                                {
                                  id: generateId(),
                                  rule: { combinator: 'and', rules: [] },
                                  effects: [],
                                },
                              ]);
                            }}
                          >
                            <i className="bx bx-plus fs-18"></i> Create Rule
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                {!promotionId?.includes('__v') && (
                  <ModalFooter className="sticky-modal-footer">
                    <div className="d-flex gap-2 justify-content-end">
                      <Button
                        outline
                        color="danger"
                        size="md"
                        onClick={onCancel}
                      >
                        Cancel
                      </Button>

                      <Button
                        loading={false}
                        type="submit"
                        color="primary"
                        size="md"
                        className="bg-gradient px-5"
                        disabled={isSubmitting}
                      >
                        {promotionDetail?.promotionName
                          ? 'Save Draft'
                          : 'Save & Next'}
                      </Button>
                    </div>
                  </ModalFooter>
                )}
              </Form>
            </FormikProvider>
          </DragDropContext>

          {isLoading ? <InnerLoader /> : null}
        </Col>
        <Col>
          <Row
            lg={12}
            md={12}
            marginX={2}
            backgroundColor={'#e3f2fd'}
            borderColor={'#80deea'}
            borderRadius={1}
            height={'100%'}
            width={'100%'}
          >
            <BasketPlayground
              productEligibilityQuery={productEligibilityQuery}
              promotionId={promotionId}
              tiers={tiers}
              promotionDetail={promotionDetail}
            />
          </Row>
        </Col>
      </Row>
    </CardBody>
  );
};

PromotionTiers.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  updateHandler: PropTypes.func,
  partners: PropTypes.array,
};

export default PromotionTiers;
