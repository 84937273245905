import React, { useState, useEffect } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const PartnerEffectContribution = ({
  eIndex,
  tIndex,
  effect,
  effectChangeHandler,
  partners,
}) => {
  const handleInputChange = (index, field, value) => {
    try {
      const updatedPartners = [...(effect?.partners || [])];
      updatedPartners[index][field] = value;
      effectChangeHandler('partners', updatedPartners, eIndex, tIndex);
    } catch (e) {
      console.log(e);
    }
  };

  const addPartnerRow = () => {
    try {
      const updatedPartners = [
        ...(effect?.partners || []),
        {
          partnerId: '',
          partnerName: '',
          contributionPercentage: 0,
        },
      ];
      effectChangeHandler('partners', updatedPartners, eIndex, tIndex);
    } catch (e) {
      console.log(e);
    }
  };

  const removePartnerRow = (index) => {
    try {
      const updatedPartners = effect?.partners?.filter((_, i) => i !== index);
      effectChangeHandler('partners', updatedPartners, eIndex, tIndex);
    } catch (e) {
      console.log(e);
    }
  };

  return partners?.length > 0 ? (
    <div className="d-flex flex-column w-100">
      {effect?.partners?.map((item, index) => (
        <div key={item.id} className="d-flex flex-row align-items-center gap-2">
          <FormGroup>
            <Label for={`partner-${index}`}>{`Partner - ${index + 1}`}</Label>
            <Input
              type="select"
              id={`partner-${index}`}
              value={item?.partnerId}
              onChange={(e) => {
                handleInputChange(index, 'partnerId', e?.target?.value);
                handleInputChange(
                  index,
                  'partnerName',
                  e?.target?.options[e?.target?.selectedIndex]?.text
                );
              }}
            >
              <option value="">Select a partner</option>
              {partners?.map((partner) => (
                <option value={partner?._id}>{partner?.partnerName}</option>
              ))}
            </Input>
          </FormGroup>

          <FormGroup className="ms-3">
            <Label for={`value-${index}`}>Contribution Percentage</Label>
            <Slider
              id={`value-${index}`}
              min={0}
              max={100}
              step={1}
              value={item?.contributionPercentage || 0}
              onChange={(value) =>
                handleInputChange(index, 'contributionPercentage', value)
              }
              style={{
                width: 300,
              }}
            />
            <div>{item?.contributionPercentage}%</div>
          </FormGroup>

          <Button
            color="transparent"
            size="sm"
            onClick={() => removePartnerRow(index)}
            className="p-0 border-0 shadow-none btn btn-danger rule-remove"
          >
            <i class="bx bx-x fs-18"></i>
          </Button>
        </div>
      ))}

      <div className="w-auto">
        <Button
          color="primary"
          size="sm"
          className="fit-content"
          onClick={addPartnerRow}
        >
          Add Partner
        </Button>
      </div>
    </div>
  ) : null;
};

export default PartnerEffectContribution;

PartnerEffectContribution.propTypes = {
  eIndex: PropTypes.number.isRequired,
  tIndex: PropTypes.number.isRequired,
  effect: PropTypes.object.isRequired,
  effectChangeHandler: PropTypes.func.isRequired,
  partners: PropTypes.array.isRequired,
};
