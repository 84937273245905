import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const CustomPagination = ({
  totalPages = 1,
  pageOffset = 1,
  setPageOffset = () => {},
  paginationHandler = () => {},
}) => {
  const onPageChange = (offset) => {
    if (offset >= 0 && offset < totalPages) {
      paginationHandler(offset);
      setPageOffset(offset);
    }
  };

  const maxPageButtons = 10;
  const startPage = Math.max(0, pageOffset - Math.floor(maxPageButtons / 2));
  const endPage = Math.min(totalPages, startPage + maxPageButtons);

  return (
    <div className="align-items-center m-3 pt-2 justify-content-between row text-center text-sm-start">
      <div className="col-sm"></div>
      {totalPages > 1 && (
        <div className="col-sm-auto mt-3 mt-sm-0">
          <ul className="pagination pagination-separated pagination-sm mb-0 justify-content-center">
            {/* Previous Page Button */}
            <li className={`page-item ${pageOffset <= 0 ? 'disabled' : ''}`}>
              <Link
                to="#"
                className="page-link"
                onClick={() => onPageChange(pageOffset - 1)}
              >
                &larr;
              </Link>
            </li>

            {/* First Page & Ellipsis */}
            {startPage > 0 && (
              <>
                <li className="page-item">
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => onPageChange(0)}
                  >
                    1
                  </Link>
                </li>
                {startPage > 1 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
              </>
            )}

            {/* Page Numbers */}
            {Array.from({ length: endPage - startPage }, (_, i) => {
              const pageIndex = startPage + i;
              return (
                <li
                  key={pageIndex}
                  className={`page-item ${
                    pageOffset === pageIndex ? 'active' : ''
                  }`}
                >
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => onPageChange(pageIndex)}
                  >
                    {pageIndex + 1}
                  </Link>
                </li>
              );
            })}

            {/* Last Page & Ellipsis */}
            {endPage < totalPages && (
              <>
                {endPage < totalPages - 1 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                <li className="page-item">
                  <Link
                    to="#"
                    className="page-link"
                    onClick={() => onPageChange(totalPages - 1)}
                  >
                    {totalPages}
                  </Link>
                </li>
              </>
            )}

            {/* Next Page Button */}
            <li
              className={`page-item ${
                pageOffset >= totalPages - 1 ? 'disabled' : ''
              }`}
            >
              <Link
                to="#"
                className="page-link"
                onClick={() => onPageChange(pageOffset + 1)}
              >
                &rarr;
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

CustomPagination.propTypes = {
  totalPages: PropTypes.number.isRequired,
  pageOffset: PropTypes.number.isRequired,
  setPageOffset: PropTypes.func.isRequired,
  paginationHandler: PropTypes.func.isRequired,
};

export default CustomPagination;
