import { defaultOperators, toFullOption } from 'react-querybuilder';
import booleanify from 'booleanify';
const customOperators = [
  {
    name: 'some',
    value: 'some',
    label: 'contains any of',
    valueSources: ['value'],
    jsonLogic: 'some',
  },
  {
    name: 'none',
    value: 'none',
    label: 'does not contain any of',
    valueSources: ['value'],
    jsonLogic: 'none',
  },
];

const customAndDefaultOperators = [...defaultOperators, ...customOperators];

export const convertFieldToQueryBuilderFormat = (fields) => {
  const inputTypes = {
    float: 'number',
    integer: 'number',
    double: 'number',
    text: 'text',
    string: 'text',
    datetime: 'date',
    'datetime-local': 'date',
  };

  const fieldTypeToValueEditorTypeMap = {
    text: 'text', // Text Field -> text input
    number: 'text', // Number Field -> text input for numbers
    textarea: 'textarea', // Textarea -> multiline text input
    multiselect: 'multiselect', // Multi Select -> multiselect dropdown
    select: 'select', // Dropdown -> single-select dropdown
    datetime: 'datetime-local', // Date & Time -> date and time picker
    date: 'date', // Date -> date picker
    radio: 'radio', // Single Select (Radio) -> radio buttons
    checkbox: 'checkbox', // Multi Select (Checkbox) -> checkboxes
    switch: null, // Switch -> not directly mapped, might need custom handling
    treeData: null, // Tree Picker -> custom editor, likely not applicable to standard types
    jsonValue: 'textarea', // JSON values -> textarea for JSON input
  };
  return fields
    .map((field) => {
      const { dataType } = field;
      return {
        ...field,
        name: `${field.fieldGroup}.${field.fieldCode}`,
        label: field.fieldName, //
        placeholder: field.placeholder,
        errorMessages: field.errorMessages,
        group: field.fieldGroup,
        defaultValue: field.defaultValue || null, //
        operators: customAndDefaultOperators.filter((opt) => {
          if (field.fieldType === 'number')
            return [
              '=',
              '!=',
              '>',
              '>=',
              '<',
              '<=',
              'between',
              'notBetween',
            ].includes(opt.name);
          return true;
        }), //defaultOperators.filter((op) => op.name === 'in'),
        // validator,
        valueEditorType: fieldTypeToValueEditorTypeMap[field?.fieldType],

        inputType: inputTypes[field.dataType],
        valueSources: field?.canCompareWithOtherFieldValue
          ? ['value', 'field']
          : undefined,
        values:
          field?.optionValues?.map((opt) => {
            return {
              name: dataType === 'boolean' ? booleanify(opt.value) : opt.value,
              label: opt.label,
            };
          }) || [],
      };
    })
    .map((o) => toFullOption(o));
};

export const convertMemberFieldsToQueryBuilderFormat = (configs) => {
  return configs
    .map((config) => {
      let fieldName = '';
      let fieldLabel = '';
      config.values.map((val) => {
        if (val.fieldCode === 'configLabel') {
          fieldLabel = val.fieldValue;
        } else if (val.fieldCode === 'fieldId') {
          fieldName = val.fieldValue;
        }
      });
      return {
        ...config,
        name: `member.${fieldName}`,
        label: fieldLabel, //
        // placeholder: field.placeholder,
        // errorMessages: field.errorMessages,
        group: 'member',
        // defaultValue: field.defaultValue || null, //
        operators: customAndDefaultOperators.filter((opt) => {
          if (config.dataType === 'number')
            return [
              '=',
              '!=',
              '>',
              '>=',
              '<',
              '<=',
              'between',
              'notBetween',
            ].includes(opt.name);
          return true;
        }), //defaultOperators.filter((op) => op.name === 'in'),
        // validator,
        valueEditorType:
          config?.fieldType === 'datetime'
            ? 'datetime-local'
            : config?.fieldType,
        values:
          config?.optionValues?.map((opt) => {
            return { name: opt.value, label: opt.label };
          }) || [],
      };
    })
    .map((o) => toFullOption(o));
};
