import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const DeleteConfirmation = ({ onClose, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Button
        aria-label="delete"
        onClick={() => setIsOpen(true)}
        size="sm"
        color="transparent"
        className="btn px-1 py-1 border-radius-0 shadow-none"
      >
        <i className="bx bx-trash fs-18" style={{ color: 'red' }}></i>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="sm" centered>
        <ModalHeader>Delete</ModalHeader>
        <ModalBody>
          <p>Are you sure you want to delete?</p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)} size="sm" color="primary">
            Cancel
          </Button>
          <Button onClick={onDelete} size="sm" color="danger">
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteConfirmation;
