import React, { useEffect, useState } from 'react';
import { useLocation, Link, Outlet, useParams } from 'react-router-dom';
import {
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Container,
  Button,
  Row,
  Col,
} from 'reactstrap';
import {
  getPromotionDetail,
  publishPromotion,
  getPromotionDetailByVersion,
} from '../../actions/promotion';
import PromotionBasicSettings from './PromotionBasicSettings';
import PromotionCoupons from './PromotionCoupons';
import PromotionTiers from './PromotionTiers';
import { alertError, alertSuccess } from '../../helpers/errorHandling';
import PromotionHistory from './PromotionHistory';
import { useNavigate } from 'react-router-dom';
import PromotionMessagesAndNotifications from './PromotionMessagesAndNotifications';
import PromotionRedemption from './PromotionRedemption';
import PromotionAnalysis from './PromotionAnalysis';
import PromotionLauncher from './PromotionLauncher';
import { getPromotionLauncherDetails } from '../../actions/promotion/launcher';
import { getAllConfigs } from '../../actions/promotion/generals';
import { useDispatch } from 'react-redux';
import { pausePromotion } from '../../actions/promotion';
import { getPartners } from '../../actions/partner';

const PromotionDetail = ({ promotionIdFromPopup, isPopup = false }) => {
  const { pathname } = useLocation();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [promotionDetail, setPromotionDetail] = useState({});
  const [activeTab, setActiveTab] = useState('0');
  const promotionParamsId = params?.id || promotionIdFromPopup;
  const [launcherDetails, setLauncherDetails] = useState({});
  const [basicSettingsMetaFields, setBasicSettingsMetaFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    let selectedTab = '0';
    switch (pathname) {
      case `/promotions/detail/${params.id}/triggers`:
        selectedTab = '2';
        break;
      case `/promotions/detail/${params.id}/conditions-effects`:
        selectedTab = '3';
        break;
      case `/promotions/detail/${params.id}/budgeting`:
        selectedTab = '4';
        break;
      case `/promotions/detail/${params.id}/cost-distribution`:
        selectedTab = '5';
        break;
      case `/promotions/detail/${params.id}/messages-and-notifications`:
        selectedTab = '6';
        break;
      case `/promotions/detail/${params.id}/redemptions`:
        selectedTab = '7';
        break;
      case `/promotions/detail/${params.id}/analysis`:
        selectedTab = '8';
        break;
      case `/promotions/detail/${params.id}/launcher`:
        selectedTab = '9';
        break;

      default:
        selectedTab = '0';
    }
    setActiveTab(selectedTab);
  }, [pathname]);

  const fetchPromotionDetail = async (promotionId) => {
    try {
      const result = await getPromotionDetail({ promotionId });
      setPromotionDetail(result.getPromotionDetail);
    } catch (e) {
      console.log(e);
    }
  };
  const getConfigs = async () => {
    try {
      setLoading(true);
      const { data, meta } = await getAllConfigs({
        configName: 'basic-meta',
        configEnv: process.env.NODE_ENV,
        pageOffset: 0,
        pageSize: 2000,
      });
      await getAllConfigs(
        {
          configName: 'languages',
          configEnv: process.env.NODE_ENV,
          pageOffset: 0,
          pageSize: 2000,
        },
        dispatch
      );

      setTimeout(() => {
        setBasicSettingsMetaFields(data);
        setLoading(false);
      }, 400);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    try {
      if (promotionParamsId !== 'new') {
        if (promotionParamsId?.includes('__v')) {
          getPromotionDetailByVersionFunc(promotionParamsId);
        } else {
          fetchPromotionDetail(promotionParamsId);
        }
      }
      getConfigs();
    } catch (e) {
      console.log(e);
    }
  }, [promotionParamsId]);

  useEffect(() => {
    if (promotionDetail?.launcher?.launcherId) {
      getPromotionLauncherDetailsFunc();
    }
  }, [promotionDetail]);

  useEffect(() => {
    getPartnersFunc();
  }, []);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const publishPromotionFunc = async () => {
    try {
      let promotionId = promotionParamsId;
      let versionId = null;

      if (promotionParamsId?.includes('__v')) {
        versionId = promotionParamsId;
        promotionId = promotionParamsId?.substring(
          0,
          promotionParamsId?.indexOf('__v')
        );
      }

      const variables = {
        input: {
          promotionId: promotionId,
          versionId: versionId,
          launcherId: promotionDetail?.launcher?.launcherId,
        },
      };

      const { success, message } = await publishPromotion(variables);
      if (success) {
        navigate(`/promotions/detail/${promotionId}/basic`);
        fetchPromotionDetail(promotionId);
        alertSuccess(message);
      } else {
        alertError(message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const pausePromotionFunc = async () => {
    try {
      const { success, message } = await pausePromotion({
        promotionId: promotionDetail?._id,
      });

      if (success) {
        fetchPromotionDetail(promotionDetail?._id);
        alertSuccess(message);
      } else {
        alertError(message);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPromotionLauncherDetailsFunc = async () => {
    try {
      const { success, data } = await getPromotionLauncherDetails({
        _id: promotionDetail?.launcher?.launcherId,
      });
      if (success) {
        setLauncherDetails(data);
      } else {
        alertError({ message: 'Failed!, Please try again.' });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPromotionDetailByVersionFunc = async (versionId) => {
    try {
      const variables = {
        input: {
          versionId: versionId,
        },
      };
      const { data, success } = await getPromotionDetailByVersion(variables);
      if (success) {
        setPromotionDetail({ ...data, _id: promotionDetail?._id });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getPartnersFunc = async () => {
    try {
      const data = {
        pageSize: 100,
        pageOffset: 0,
      };
      const res = await getPartners(data);
      setPartners(res?.partners || []);
    } catch (e) {
      console.log(e);
    }
  };

  const updateHandler = () => {
    fetchPromotionDetail(promotionParamsId);
  };

  return (
    <div className="page-content">
      <Container fluid>
        <Card>
          <Row className="align-items-center justify-content-between ps-4 pe-4 pt-4 pb-2">
            <Col xs="auto">
              {promotionDetail?.basicSettings?.promotionName ? (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  {`Promotion Details - ${promotionDetail?.basicSettings?.promotionName}`}
                </h4>
              ) : (
                <h4 className="mb-sm-0 fw-medium text-uppercase fs-16">
                  Promotion Details
                </h4>
              )}
            </Col>

            <Col xs="auto">
              {promotionDetail?.version > 0 ? (
                <PromotionHistory promotionDetail={promotionDetail} />
              ) : null}

              {promotionDetail?.status === 'updated' ||
              promotionDetail?.status === 'paused' ||
              promotionParamsId?.includes('__v') ? (
                <Button
                  size="sm"
                  color="primary"
                  className="ms-2 px-4"
                  onClick={() => publishPromotionFunc()}
                >
                  {promotionParamsId?.includes('__v')
                    ? 'Restore this Version'
                    : 'Publish'}
                </Button>
              ) : null}

              {promotionDetail?.status === 'published' ? (
                <Button
                  size="sm"
                  color="primary"
                  className="ms-2 px-4"
                  onClick={() => pausePromotionFunc()}
                >
                  Pause
                </Button>
              ) : null}
            </Col>
          </Row>
          <Nav tabs className="nav  nav-tabs-custom rounded ">
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '0' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('0')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/basic`}
              >
                <i className="bx bx-user ms-2 me-2 fs-18"></i>
                Basic Details
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${
                  activeTab === '2' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('2')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/triggers`}
              >
                <i className="bx bx-badge-check ms-2 me-2 fs-18"></i>
                Triggers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '3' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('3')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/detail/${params.id}/conditions-effects`
                }
              >
                <i className="bx bx-info-circle ms-2 me-2 fs-18"></i>
                Conditions & Effects
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={`${
                  activeTab === '6' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('6')}
                tag={Link}
                to={
                  !isPopup &&
                  `/promotions/detail/${params.id}/messages-and-notifications`
                }
              >
                <i className="bx bx-envelope ms-2 me-2 fs-18"></i>
                Messages & Notifications
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '7' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('7')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/redemptions`}
              >
                <i className="bx bx-gift ms-2 me-2 fs-18"></i>
                Redemptions
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '8' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('8')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/analysis`}
              >
                <i className="bx bx-chart ms-2 me-2 fs-18"></i>
                Analysis
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={`${
                  activeTab === '9' ? 'active' : ''
                } d-flex align-items-center justify-content-center`}
                onClick={() => toggle('9')}
                tag={Link}
                to={!isPopup && `/promotions/detail/${params.id}/launcher`}
              >
                <i className="bx bx-rocket ms-2 me-2 fs-18"></i>
                Launcher
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId={activeTab}>
              {activeTab === '0' ? (
                <PromotionBasicSettings
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                  basicSettingsMetaFields={basicSettingsMetaFields}
                />
              ) : null}

              {activeTab === '2' ? (
                <PromotionCoupons
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '3' ? (
                <PromotionTiers
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                  partners={partners}
                />
              ) : null}

              {activeTab === '6' ? (
                <PromotionMessagesAndNotifications
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '7' ? (
                <PromotionRedemption
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                />
              ) : null}
              {activeTab === '8' ? (
                <PromotionAnalysis
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                  fetchPromotionDetail={fetchPromotionDetail}
                />
              ) : null}
              {activeTab === '9' ? (
                <PromotionLauncher
                  promotionId={promotionParamsId}
                  promotionDetail={promotionDetail}
                  updateHandler={updateHandler}
                  fetchPromotionDetail={fetchPromotionDetail}
                  launcherDetails={launcherDetails}
                />
              ) : null}
            </TabPane>
          </TabContent>
        </Card>
      </Container>
    </div>
  );
};

export default PromotionDetail;
