import promoRestService from '../../services/PromoRestService';

export const getPartners = async (data) => {
  try {
    const result = await promoRestService?.post('api/v1/partner/list', data);
    return result?.data;
  } catch (e) {
    console.log(e);
  }
};
