import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ButtonGroup,
} from 'reactstrap';
import LoadingOverlay from 'react-loading-overlay';

// third-party

import './coupon.scss';

// project-imports

import { updatePromotionTriggers } from '../../../actions/promotion';

import { QueryBuilderApp } from '../QueryBuilderApp';
import { alertError, alertSuccess } from '../../../helpers/errorHandling';
import InnerLoader from '../../../components/Common/InnerLoader';

import CheckboxBigSquare from '../../../components/Common/CheckboxBigSquare';
import CreateCouponOptions from './CreateCouponOptions';
import { Else, If, Then } from 'react-if';
import ErrorMessagesInLang from '../../Configuration/Fields/ErrorMessagesInLang';

// constant
const fieldGroups = ['basket', 'user', 'sys'];
export const DISCARD_SUBSEQUENTLY_OPTIONS = [
  {
    value: 'except_individual',
    label: 'All Promotions(Except ALWAYS RUN)',
  },
  {
    value: 'select_promotions',
    label: 'Only Select Promotions',
  },
  {
    value: 'all_except_few_selected',
    label: 'All Promotions Except Few Selected',
  },
];
const DISCARD_SUBSEQUENTLY_OPTIONS_OBJ_TMP = {};
DISCARD_SUBSEQUENTLY_OPTIONS.forEach((obj) => {
  DISCARD_SUBSEQUENTLY_OPTIONS_OBJ_TMP[obj.value] = obj;
});
export const DISCARD_SUBSEQUENTLY_OPTIONS_OBJ =
  DISCARD_SUBSEQUENTLY_OPTIONS_OBJ_TMP;

const PromotionCoupons = ({
  onCancel = () => {},
  getStores = () => {},
  promotionId,
  promotionDetail,
  updateHandler = () => {},
}) => {
  let navigate = useNavigate();

  const [currentTriggerType, setCurrentTriggerType] = useState(
    promotionDetail?.triggerType
  );
  const [coupons, setCoupons] = useState([]);

  const [couponQuery, setCouponQuery] = useState(null);
  const [triggerErrorMessages, setTriggerErrorMessages] = useState([]);
  const [priority, setPriority] = useState(0);
  const [discard, setDiscard] = useState('except_individual');
  const [alwaysRun, setAlwaysRun] = useState(false);
  const [promotionAppliedToAllItems, setpromotionAppliedToAllItems] =
    useState(false);
  const [combinableWithPromotion, setcombinableWithPromotion] = useState([]);

  useEffect(() => {
    setCouponQuery(promotionDetail?.triggerRules);
    setPriority(promotionDetail?.basicSettings?.priority);
    setDiscard(promotionDetail?.basicSettings?.discard);
    setAlwaysRun(promotionDetail?.basicSettings?.alwaysRun);
    setTriggerErrorMessages(
      triggerErrorMessages.length
        ? triggerErrorMessages
        : promotionDetail?.triggerErrorMessages || []
    );
    setpromotionAppliedToAllItems(
      promotionDetail?.basicSettings?.promotionAppliedToAllItems
    );
    setcombinableWithPromotion(
      promotionDetail?.basicSettings?.combinableWithPromotion
    );
    setCurrentTriggerType(promotionDetail?.triggerType);
  }, [promotionDetail]);

  const saveTriggers = async () => {
    // dispatch(setIsShowRuleValidationError(true));
    if (true) {
      // const triggers = generateTriggerJsonLogic(query);
      const variables = {
        input: {
          promotionId: promotionId,
          triggerRules: couponQuery,
          triggerErrorMessages,
          triggerType: currentTriggerType,
          discard,
          priority,
          alwaysRun,
          combinableWithPromotion,
          promotionAppliedToAllItems,
        },
      };

      const { success, data, message } = await updatePromotionTriggers(
        variables
      );
      if (success) {
        alertSuccess(message);
      } else {
        alertError('Failed!, Please try again after some time');
      }
      // dispatch(setIsShowRuleValidationError(false));
    } else {
      console.log('Invalid query');
    }
  };

  return (
    <CardBody className="p-4">
      <Row className="mb-4">
        <Col xs={12}>
          <h6 class="mb-0 fw-semibold text-uppercase">Trigger Type</h6>
          <div className="mb-2">
            <p class="text-muted">When to trigger this campaign?</p>
          </div>
          <Row>
            <CheckboxBigSquare
              id="manual-coupon"
              title="Applying a Coupon"
              description=" This promotion will be triggered when a coupon is
                        applied"
              onChange={(event) => {
                setCurrentTriggerType('coupon');
              }}
              checked={currentTriggerType === 'coupon'}
              icon="bx bxs-coupon"
            />
            <CheckboxBigSquare
              id="auto-applied"
              title="Auto Applied"
              description="This promotion is auto applied when the trigger
                        conditions are met"
              onChange={(event) => {
                setCurrentTriggerType('autoapply');
              }}
              checked={currentTriggerType === 'autoapply'}
              icon="ri-compass-discover-line"
            />
            <CheckboxBigSquare
              id="activity"
              title="Activity Based"
              description="This campaign is activated based on an activity or an event. Eg: User Registration, Wishlist (Not on transactions)"
              onChange={(event) => {
                setCurrentTriggerType('activity');
              }}
              checked={currentTriggerType === 'activity'}
              icon="ri-flag-2-line"
            />
            <CheckboxBigSquare
              id="calendar-based"
              title="User Event Based"
              description="This campaign is triggered based on user eg:- birthday, anniversary, etc."
              onChange={(event) => {
                setCurrentTriggerType('event');
              }}
              checked={currentTriggerType === 'event'}
              icon="ri-calendar-event-line"
            />

            <div />
          </Row>
        </Col>
      </Row>
      {/* <If condition={currentTriggerType === 'autoapply'}> */}
      <>
        <Row className="mb-3">
          <Col>
            <h6 class="mb-0 fw-semibold text-uppercase">ALWAYS RUN</h6>

            <div class="form-check form-switch form-switch-lg" dir="ltr">
              <input
                type="checkbox"
                class="form-check-input"
                id="alwaysRun"
                checked={alwaysRun}
                onChange={(event) => {
                  setAlwaysRun(event.target.checked);
                }}
              />
            </div>
            <If condition={alwaysRun}>
              <>
                <small class="text-muted">
                  This promotion will run regradless of other promotion's
                  "Discard Subsequently" settings
                </small>
              </>
            </If>
          </Col>
        </Row>
        <If condition={!alwaysRun}>
          <Row className="mb-3">
            <Col>
              <h6 class="mb-0 fw-semibold text-uppercase">
                CAN COMBINE WITH OTHER PROMOTION
              </h6>

              <div class="form-check form-switch form-switch-lg ps-0" dir="ltr">
                {/* <input
                  type="checkbox"
                  class="form-check-input"
                  id="combinableWithPromotion"
                  checked={combinableWithPromotion}
                  onChange={(event) => {
                    setcombinableWithPromotion(event.target.checked);
                  }}
                /> */}
                <CreatableSelect
                  id="combinableWithPromotion"
                  isMulti
                  options={[]}
                  value={combinableWithPromotion?.map((tag) => ({
                    value: tag,
                    label: tag,
                  }))}
                  onChange={(selected) => {
                    const selectedValues = selected
                      ? selected.map((item) => item.value)
                      : [];
                    setcombinableWithPromotion(selectedValues);
                  }}
                  classNamePrefix="select"
                />
              </div>
              <If condition={combinableWithPromotion}>
                <>
                  <small class="text-muted">
                    This promotion will be applied along with any coupon code
                    promotion.
                  </small>
                </>
              </If>
            </Col>
          </Row>
        </If>

        <Row className="mb-3">
          <Col>
            <h6 class="mb-0 fw-semibold text-uppercase">
              Apply Promotion to All Items
            </h6>

            <div class="form-check form-switch form-switch-lg" dir="ltr">
              <input
                type="checkbox"
                class="form-check-input"
                id="promotionAppliedToAllItems"
                checked={promotionAppliedToAllItems}
                onChange={(event) => {
                  setpromotionAppliedToAllItems(event.target.checked);
                }}
              />
            </div>

            <small class="text-muted">
              Check this box to apply the promotion to all items. Unchecking
              will restrict the promotion to only items without an existing
              promotion discount.
            </small>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <h6 class="mb-0 fw-semibold text-uppercase">
                <Label for="discard">Discard Subsequently </Label>
              </h6>
              <Select
                options={DISCARD_SUBSEQUENTLY_OPTIONS}
                value={DISCARD_SUBSEQUENTLY_OPTIONS.find((opt) => {
                  return opt.value === discard;
                })}
                onChange={(selected) => {
                  setDiscard(selected?.value);
                }}
                // classNamePrefix="select"
              />
            </FormGroup>
          </Col>
        </Row>
      </>
      {/* </If> */}

      {/* {!promotionId?.includes('__v') && (
        <Row className="mb-2">
          <Col xs={12}>
            <FormGroup check inline>
              <Label check>
                <Input
                  type="checkbox"
                  checked={!isCouponRequired}
                  onChange={() => setIsCouponRequired(!isCouponRequired)}
                  size="md"
                />
                No Coupon Required
              </Label>
            </FormGroup>
          </Col>
        </Row>
      )} */}

      {currentTriggerType === 'coupon' ? (
        <CreateCouponOptions
          promotionId={promotionId}
          promotionDetail={promotionDetail}
          onCancel={onCancel}
        />
      ) : null}
      <>
        <Row className="mb-4 mt-4">
          <Col>
            <h6 class="mb-0 fw-semibold text-uppercase">Trigger Condition</h6>
            <div className="mb-2">
              <p class="text-muted">
                This promotion will be applied only when the below conditions
                are met
              </p>
            </div>
            <QueryBuilderApp
              updateQuery={setCouponQuery}
              data={couponQuery}
              fieldGroups={fieldGroups}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt-2">
            <FormGroup>
              <ErrorMessagesInLang
                value={promotionDetail.triggerErrorMessages || []}
                onChange={(values) => {
                  setTriggerErrorMessages(values.filter((val) => val.value));
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        {!promotionId?.includes('__v') && (
          <div className="d-flex gap-2 justify-content-end">
            <Button outline color="danger" size="md" onClick={() => {}}>
              Cancel
            </Button>

            <Button
              loading={false}
              color="primary"
              size="md"
              className="bg-gradient px-5"
              onClick={() => {
                saveTriggers();
              }}
            >
              {promotionDetail ? 'Save Draft' : 'Save & Next'}
            </Button>
          </div>
        )}
      </>
    </CardBody>
  );
};

PromotionCoupons.propTypes = {
  promotion: PropTypes.any,
  onCancel: PropTypes.func,
  promotionId: PropTypes.any,
  promotionDetail: PropTypes.any,
  updateHandler: PropTypes.func,
};

export default PromotionCoupons;
